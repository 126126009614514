import React from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import EventLocation from '../../svg/event-location.svg'
import EventDate from '../../svg/event-date.svg'

import '../../scss/event-card.scss'

const EventCard = ({ event, minWidth, maxWidth, floating = false, handleClick }) => {    

    const eventClick = () => {
        navigate('/events?event=' + event.slug)
        // handleClick(event)
    }

    const date = new Date(event?.eventData?.startDate)
    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };

    return (
        <div onClick={eventClick} className={`event-card ${floating ? 'floating' : ''}`} style={{
            maxWidth: maxWidth ? maxWidth : 'inherit',
            minWidth: minWidth ? minWidth : 'inherit',
        }}>
            <div className="image">
                <div className="overlay"></div>
                {event?.featuredImage?.image?.localFile?.childImageSharp?.gatsbyImageData ? (
                    <GatsbyImage
                        objectFit="contain"
                        alt={event?.featuredImage?.image?.altText ? event?.featuredImage?.image?.altText : event?.title}
                        image={event?.featuredImage?.image?.localFile?.childImageSharp?.gatsbyImageData}
                    />
                ) : (
                    <div style={{ backgroundColor: '#ddd', height: '100%', width: '100%' }} />
                )}
            </div>
            <div className="details">
                <h3>
                    {event?.title}
                </h3>
                <span className="location">
                    <EventLocation /> {event?.eventData?.location?.city}, {event?.eventData?.location?.stateShort}
                </span>
                <span className="date">
                    <EventDate /> {date.toLocaleDateString('en-US', options)}
                </span>
            </div>
        </div>
    )
}

export default EventCard
